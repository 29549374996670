<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-btn @click="addIncident">Add Incident</v-btn>
      <v-col cols="12">
        <h3>Incidents</h3>
        {{incidents}}
        <v-data-table
          id="list-table"
          ref="datatable"
          :headers="headers"
          :items="incidents"
          item-key="index"
          class="mr-2"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.incidentOverview.incidentTitle}}</td>
              <td>{{ props.item.incidentCategory==undefined?"": props.item.incidentCategory.incidentPrimaryCategory }}</td>
              <td>{{ props.item.incidentOverview==undefined?"":props.item.incidentOverview.incidentNotificationType }}</td>
              <td>{{ props.item.providerDetails==undefined?"": props.item.providerDetails.reportCompletedBy }}</td>

              <td>{{ moment(props.item.createdOn).format("DD-MM-YYYY")}}</td>

              <td>
                <v-icon slot="activator" small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as incidentService from "../../../services/incidentsService";
Vue.use(VeeValidate);
import moment from "moment";
export default {
  name: "incidents",
  components: {},
  data: () => ({
    editedIndex: -1,
    vm: {},

    dialog: false,
    incidents: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Incident Title", value: "incidentTitle" },
      { text: "Primary Category", value: "incidentPrimaryCategory" },
      { text: "Notification Type", value: "incidentNotificationType" },
      { text: "Report Completed By", value: "reportCompletedBy" },
      { text: "Created on", value: "createdon" }
    ]
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },

    init() {
      this.vm = {};
      incidentService
        .list(this.currentOrganisation.id, this.currentCase.id)
        .then(result => {
          let incidents = [];
          result.docs.map(doc => {
            const incident = doc.data();
            incident.id = doc.id;
            incidents.push(incident);
          });
          this.incidents = incidents;
        });
    },
    editItem(item) {
      this.$store.commit("setCurrentIncident", item);
      this.$router.push("/participant-management/incidents/main-form");
    },

    addIncident() {
      this.$store.commit("setCurrentIncident", null);
      this.$router.push("/participant-management/incidents/main-form");
    }
  }
};
</script>
<style>
.v-card--material-stats:hover {
  border-top: 4px solid #4caf50;
}
</style>